import {
  // getUserId,
  // getWatchMode,
  // getModelName,
  getContentId,
  getMediaMetaData,
  getMediaCustomData,
} from './requestUtils';
import {
  APP_VERSION,
  PLAYER_NAME,
  // PLATFORM,
  STREAM_TYPE_DASH,
  DRM_NAME_WIDEVINE,
  VENDOR,
  LICENSE_NAME_WIDEVINE,
  apiUrls,
  SUPPORTS_50FPS,
} from './constants';
import analyticsFactory from './services/analytics';
import deviceInfo from './utils/deviceInfo';
// import { isCmafSupported } from './uiUtils';

const {
  getPlaybackItem,
  getPlaybackItemData: getPlaybackItmData,
} = analyticsFactory;

export const getPlaybackItemData = getPlaybackItmData;

export function isCmafSupported(config, isLive) {
  let cmafLiveSupportedDevices = [];
  let cmafVodSupportedDevices = [];
  let cmaf = false;

  const chromecastModelName = deviceInfo();

  if (config && config.getConfig) {
    cmaf = config.getConfig('cmaf') || false;
    cmafLiveSupportedDevices =
      config.getConfig('cmafLiveSupportedDevices') || [];
    cmafVodSupportedDevices = config.getConfig('cmafVodSupportedDevices') || [];
  }

  const supportsCmaf = isLive
    ? cmafLiveSupportedDevices.includes(chromecastModelName)
    : cmafVodSupportedDevices.includes(chromecastModelName);

  return cmaf && supportsCmaf;
}

export function isCmafCtrSupported(config, isLive) {
  let cmafCtrLiveSupportedDevices = [];
  let cmafCtrVodSupportedDevices = [];
  let cmaf = false;

  const chromecastModelName = deviceInfo();

  if (config && config.getConfig) {
    cmaf = config.getConfig('cmaf') || false;
    cmafCtrLiveSupportedDevices =
      config.getConfig('cmafCtrLiveSupportedDevices') || [];
    cmafCtrVodSupportedDevices =
      config.getConfig('cmafCtrVodSupportedDevices') || [];
  }

  const supportsCmafCtr = isLive
    ? cmafCtrLiveSupportedDevices.includes(chromecastModelName)
    : cmafCtrVodSupportedDevices.includes(chromecastModelName);

  return cmaf && supportsCmafCtr;
}

function getParams(data) {
  const {
    os,
    advertConsent,
    advertId,
    deviceId,
    platform,
    watchMode,
    modelName,
    isFreeAsset,
    analyticUserId,
    supportsCmaf,
    supportsCmafCtr,
    yspSdkV3,
    yspSdkEnabled,
  } = data;

  const params = {
    advertConsent,
    advertId,
    deviceId,
    platform,
    playerName: PLAYER_NAME,
    appVersion: APP_VERSION,
    type: STREAM_TYPE_DASH,
    drm: DRM_NAME_WIDEVINE,
    vendor: VENDOR,
    model: modelName,
    os,
    watchMode,
    supportsCmaf,
    supportsCmafCtr,
    yspSdk: yspSdkV3 && yspSdkEnabled ? 'v3' : yspSdkEnabled,
  };

  if (isFreeAsset) {
    params.analyticUserId = analyticUserId;
  }

  if (SUPPORTS_50FPS) {
    params.supports50fps = true;
  }

  let result = Object.keys(params)
    .map(key => (params[key] !== null ? `${key}=${params[key]}` : null))
    .filter(item => item)
    .join('&');

  if (result) {
    result = `?${result}`;
  }

  return result;
}

export const playbackUrls = {
  general: data => {
    const { platform, assetId, userId } = data;
    const url = `${
      apiUrls.newML
    }/api/playback/generalPlayback/${platform}/users/${userId}/assets/${assetId}${getParams(
      data,
    )}`;
    return url;
  },
  free: data => {
    const { platform, assetId } = data;
    const url = `${
      apiUrls.newML
    }/api/playback/freePlayback/${platform}/assets/${assetId}${getParams(
      data,
    )}`;
    return url;
  },
};

export function getAssetMetaDataUrl(requestData = {}, platform = 'mobile') {
  const assetId = getContentId(requestData);
  return `${apiUrls.assetMetaDataUrl}/${assetId}/${platform}`;
}

export function isFreeAssetFromAccessGroups(accessGroups = []) {
  return accessGroups.includes('FREE_WITHOUT_LOGIN');
}

export function isFreeAssetFromCustomData(requestData = {}) {
  const { isFreeAsset } = getMediaCustomData(requestData);
  return isFreeAsset;
}

export function isFreeAssetFromRequest(requestData = {}) {
  // Assuming the sender does send the accessGroups coming from this API call:
  // /api/metadata/assets/v2/{contentId}/web
  // Then we can use this to determine free asset and avoid calling this API here.
  // I am assuming that I will get media.metadata.accessGroups
  const { accessGroups } = getMediaMetaData(requestData);
  if (!accessGroups) {
    // This means that media.metadata.accessGroups was not passed in from the sender.
    // Can't determine if free asset. The next calls after this can process accordingly.
    return undefined;
  }
  return isFreeAssetFromAccessGroups(accessGroups);
}

export function getPlaybackUrlArgs(castData = {}, config) {
  const props = analyticsFactory.getCommonProps({ castData });
  const {
    appProps: { advertConsent, platform },
    deviceProps: { os, advertId, deviceId, watchMode },
    userProps: { userId, analyticUserId },
    assetProps: { assetId },
  } = props;

  // console.log(props);
  const { isFreeAsset, isLive: assetLive, yspSdkOverride } = castData;

  // We need to remap generic Chromecast model names so we can figure
  // out what generation they are
  // let chromecastModelName = '';
  // if (typeof modelName === 'string' && modelName.toLowerCase() === 'chromecast') {
  //   chromecastModelName = deviceInfo();
  // }

  // OSN-2259 - just retrieve underlying Chromecast device model name
  const chromecastModelName = deviceInfo();
  const supportsCmaf = isCmafSupported(config, assetLive);
  const supportsCmafCtr = isCmafCtrSupported(config, assetLive);

  // OSN-1440 - Added new rule which can disable yspSdk for live assets
  const yspSdk = config && config.getConfig && config.getConfig('yspSdk');
  const yspSdkLive =
    config && config.getConfig && config.getConfig('yspSdkLive');
  const yspSdkLiveCmaf =
    config && config.getConfig && config.getConfig('yspSdkLiveCmaf');
  const yspSdkEnabled =
    typeof yspSdkOverride !== 'undefined'
      ? yspSdkOverride
      : (!assetLive && yspSdk) ||
        (assetLive && yspSdkLive && !supportsCmaf) ||
        (assetLive && yspSdkLiveCmaf && supportsCmaf) ||
        (assetLive && yspSdkLiveCmaf && supportsCmafCtr);

  const yspSdkV3 = config && config.getConfig && config.getConfig('yspSdkV3');

  return {
    advertConsent,
    advertId,
    deviceId,
    os,
    platform,
    assetId,
    watchMode,
    userId,
    modelName: chromecastModelName,
    supportsCmaf,
    supportsCmafCtr,
    isFreeAsset,
    analyticUserId,
    yspSdkEnabled,
    yspSdkV3,
  };
}

export function getPlaybackFlag(playbackData = {}) {
  const [item] = getPlaybackItem(playbackData);
  const { flag } = item;
  return flag;
}

export function getPlaybackContentId(playbackData = {}) {
  // TODO: Consider multiple items
  const [item] = getPlaybackItem(playbackData);
  const { url } = item;
  return url;
}

function getPlaybackLicenseData(playbackData = {}) {
  // TODO: Consider multiple items
  const [item] = getPlaybackItem(playbackData);
  const { license } = item;
  return license;
}

export function getPlaybackStreamsProvider(playbackData = {}) {
  // TODO: Consider multiple items
  const [item] = getPlaybackItem(playbackData);
  const { streamsProvider } = item;
  return streamsProvider;
}

export function getPlaybackStreamProtocol(playbackData = {}) {
  // TODO: Consider multiple items
  const [item] = getPlaybackItem(playbackData);
  const { protocol } = item;
  return protocol;
}

function getPlaybackLicenseMap(playbackData = {}) {
  const { drm } = getPlaybackLicenseData(playbackData);
  return drm.license.reduce((licenses, currLicense) => {
    const licenseName = currLicense['@name'];
    const licenseUrl = currLicense['@uri'];
    return {
      ...licenses,
      [licenseName]: licenseUrl,
    };
  }, {});
}

export function getPlaybackLicenseUrl(playbackData = {}) {
  const license = getPlaybackLicenseData(playbackData);
  const { drm } = license;
  if (!drm) {
    return license['@uri'];
  }
  const licenseMap = getPlaybackLicenseMap(playbackData);
  // Chromecast always uses Widevine
  return licenseMap[LICENSE_NAME_WIDEVINE];
}

export function getPlaybackLicenseDrmData(playbackData = {}) {
  const license = getPlaybackLicenseData(playbackData);
  const { drmData } = license;
  return drmData;
}

export function isPlaybackLive(playbackData = {}) {
  const { live } = playbackData;
  return live;
}

export function getPlaybackStreamType(playbackData = {}) {
  const { streamType } = playbackData;
  return streamType;
}

export function getPlaybackIsSSAIEnabled(playbackData = {}) {
  const { isSSAIEnabled } = playbackData;
  return isSSAIEnabled;
}

export function getPlaybackDrmProtected(playbackData = {}) {
  const { drmProtected } = playbackData;
  return drmProtected;
}
