import {
  STREAM_TYPE_LINEAR_CHANNEL,
  WATCH_MODE_LIVE,
  WATCH_MODE_START_OVER,
  WATCH_MODE_RESUME,
  WATCH_MODE_BEGINNING,
  APP_VERSION,
  mimeTypes,
} from './constants';

export function getMediaData(requestData = {}) {
  const { media } = requestData;
  return media || {};
}

function isLinearChannel(assetType) {
  return assetType === STREAM_TYPE_LINEAR_CHANNEL;
}

function isStreamLive(requestData = {}) {
  const { streamType = '' } = getMediaData(requestData);
  return streamType.toUpperCase() === WATCH_MODE_LIVE.toUpperCase();
}

export function getMediaCustomData(requestData = {}) {
  const { customData } = getMediaData(requestData);
  return customData || {};
}

function getInitialTimeIndexSeconds(requestData = {}) {
  const { currentTime } = requestData;
  const { startAtLive } = getMediaCustomData(requestData);
  return startAtLive ? null : Math.max(currentTime || 0, 0);
}

export function getWatchMode(requestData = {}) {
  const { assetType, startAtLive } = getMediaCustomData(requestData);
  if (isStreamLive(requestData)) {
    return startAtLive ? WATCH_MODE_LIVE : WATCH_MODE_START_OVER;
  }
  if (isLinearChannel(assetType)) {
    return WATCH_MODE_LIVE;
  }
  const initialTimeIndexSeconds = getInitialTimeIndexSeconds(requestData);
  return initialTimeIndexSeconds ? WATCH_MODE_RESUME : WATCH_MODE_BEGINNING;
}

export function isWatchModeLive(requestData = {}) {
  return getWatchMode(requestData) === WATCH_MODE_LIVE;
}

export function getMediaMetaData(requestData = {}) {
  const { metadata } = getMediaData(requestData);
  return metadata || {};
}

export function getContentId(requestData = {}) {
  const { contentId } = getMediaData(requestData);
  return contentId;
}

export function getIdToken(requestData = {}) {
  const { idToken } = getMediaCustomData(requestData);
  return idToken;
}

export function setIdToken(idToken, requestData = {}) {
  return {
    ...requestData,
    media: {
      ...getMediaData(requestData),
      customData: {
        ...getMediaCustomData(requestData),
        idToken,
      },
    },
  };
}

export function getRefreshToken(requestData = {}) {
  const { refreshToken } = getMediaCustomData(requestData);
  return refreshToken;
}

export function getUserId(requestData = {}) {
  const { userId } = getMediaCustomData(requestData);
  return userId;
}

export function getModelName(requestData = {}) {
  const { modelName } = getMediaCustomData(requestData);
  return modelName;
}

export function getDRModeUrl(requestData = {}) {
  const { thinclientUrl = null } = getMediaCustomData(requestData);
  return thinclientUrl;
}

export function isDRMode(requestData = {}) {
  return getDRModeUrl(requestData) !== null;
}

export function createAuthHeaders(requestData = {}) {
  const idToken = getIdToken(requestData);
  return {
    Authorization: idToken,
  };
}

export function isLoggedIn(loadRequest) {
  if (isDRMode(loadRequest)) {
    return false;
  }
  const userId = getUserId(loadRequest);
  return typeof userId !== 'undefined' && userId !== null && userId !== '';
}

export function canUseProtectedAPIs(loadRequest) {
  if (isDRMode(loadRequest)) {
    return false;
  }
  if (!isLoggedIn(loadRequest)) {
    return false;
  }
  return true;
}

function valuesToString(object) {
  return Object.entries(object).reduce((acc, curr) => {
    const [key, value] = curr;
    return {
      ...acc,
      ...(value != null && { [key]: value.toString() }),
    };
  }, {});
}

export function createCustomTags(requestData, otherTags) {
  const {
    assetTitle,
    assetType,
    categoryTitle,
    liveBroadcastDateTime,
    ...rest
  } = getMediaCustomData(requestData);
  const {
    castDeviceId,
    castDeviceVersion,
    modelName,
    senderOS,
    senderVersion,
    analyticUserId,
  } = rest;
  const { contentId, contentType } = getMediaData(requestData);
  const authenticated = isLoggedIn(requestData);

  const { homeTeamId, awayTeamId } = {}; // TODO: Get this somewhere somehow (asset-api -> match)
  // Conviva needs all tag values as string
  const props = valuesToString({
    appVersion: APP_VERSION,
    assetCategory: categoryTitle,
    category: categoryTitle, // TODO: assetCategory vs category - Current chromecast hardcoded to EPL
    homeTeamId,
    awayTeamId,
    cellId: 'NA',
    contentId,
    contentType: assetType,
    liveBroadcastTime: liveBroadcastDateTime,
    playerVersion: APP_VERSION,
    title: assetTitle,
    streamProtocol: mimeTypes[contentType],
    connectionType: 'WiFi',
    // Chromecast specific
    senderOS,
    senderVersion,
    castDeviceId,
    castDeviceVersion,
    modelName,
    // To be added as expected tags
    analyticUserId,
    ...otherTags,
  });

  return {
    ...props,
    isLoggedIn: authenticated,
  };
}

export async function createMetadata(requestData, { tags, ...metadata }) {
  const customTags = createCustomTags(requestData, tags);
  return {
    tags: {
      ...customTags,
    },
    ...metadata,
  };
}
