const Player = (playerManager, playerElement = null) => {
  const updateCallbackProps = ({ currentMediaTime, ...rest }) => {
    const newProps = {
      ...rest,
    };

    if (currentMediaTime) {
      newProps.currentPosition = currentMediaTime;
    }

    return newProps;
  };

  const init = cb => {
    playerManager.setMessageInterceptor(
      cast.framework.messages.MessageType.LOAD,
      event => cb(updateCallbackProps(event)),
    );
  };

  const infoHandler = cb => {
    playerManager.setMediaPlaybackInfoHandler(cb);
  };

  const initSeekRequest = cb => {
    playerManager.setMessageInterceptor(
      cast.framework.messages.MessageType.SEEK,
      event => cb(updateCallbackProps(event)),
    );
  };

  const initPauseRequest = cb => {
    playerManager.setMessageInterceptor(
      cast.framework.messages.MessageType.PAUSE,
      event => cb(updateCallbackProps(event)),
    );
  };

  const setVolumeRequest = cb => {
    playerManager.setMessageInterceptor(
      cast.framework.messages.MessageType.SET_VOLUME,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onAll = cb => {
    playerManager.addEventListener(cast.framework.events.EventType.ALL, event =>
      cb(updateCallbackProps(event)),
    );
  };

  const onCore = cb => {
    playerManager.addEventListener(cast.framework.events.category.CORE, event =>
      cb(updateCallbackProps(event)),
    );
  };

  const onMediaStatus = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.MEDIA_STATUS,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onTimeUpdate = cb => {
    // possibly don't need the pause / unpause - check
    // add incrementer to initialState and subtract this value from ad
    playerManager.addEventListener(
      cast.framework.events.EventType.TIME_UPDATE,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onLoadStart = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.LOAD_START,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onPlayerLoadComplete = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.PLAYER_LOAD_COMPLETE,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onMediaFinished = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.MEDIA_FINISHED,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onError = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.ERROR,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onMeta = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.EMSG,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onSeeking = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.SEEKING,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onSeeked = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.SEEKED,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onPlaying = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.PLAYING,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onPause = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.PAUSE,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onPlay = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.PLAY,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onResume = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.CAN_PLAY,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onComplete = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.MEDIA_FINISHED,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onProgressUpdate = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.PROGRESS,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onBuffering = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.BUFFERING,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onBitrateChanged = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.BITRATE_CHANGED,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onFirstFrame = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.LOADED_DATA,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onEnded = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.ENDED,
      event => cb(updateCallbackProps(event)),
    );
  };

  const onRequestStop = cb => {
    playerManager.addEventListener(
      cast.framework.events.EventType.REQUEST_STOP,
      event => cb(updateCallbackProps(event)),
    );
  };

  const getLiveSeekableRange = () => playerManager.getLiveSeekableRange();

  const getCurrentTime = () => playerManager.getCurrentTimeSec();

  const getDvrTime = time => {
    // We need to compare the current media seconds to the seekable range to calculate
    // the relative dvr time which should always be a negative number (relative to live)
    const currentMediaTime =
      typeof time !== 'undefined'
        ? time
        : playerManager.getCurrentTimeSec() || 0;
    const seekableRange = playerManager.getLiveSeekableRange();

    if (seekableRange) {
      const { end = 0 } = seekableRange;

      // End should always be greater or equal to current media time
      // as you cannot be past the live edge
      if (end && end >= currentMediaTime) {
        return currentMediaTime - end;
      }
    }
    return currentMediaTime;
  };

  const getDuration = () => playerManager.getDurationSec();

  const getPlayerState = () => playerManager.getPlayerState();

  const getStats = () => playerManager.getStats();

  const seek = position => playerManager.seek(position);

  const stop = () => playerManager.stop();

  const getPlayerElement = () => playerElement;

  return {
    playerManager,
    ...playerManager,
    getPlayerElement,
    getCurrentTime,
    getDuration,
    getState: getPlayerState,
    getStats,
    getLiveSeekableRange,
    getDvrTime,
    init,
    infoHandler,
    initPauseRequest,
    initSeekRequest,
    setVolumeRequest,
    onAll,
    onBuffering,
    onComplete,
    onCore,
    onError,
    onLoadStart,
    onMediaFinished,
    onMediaStatus,
    onMeta,
    onPause,
    onPlay,
    onPlayerLoadComplete,
    onPlaying,
    onProgressUpdate,
    onResume,
    onSeeked,
    onSeeking,
    onTimeUpdate,
    onBitrateChanged,
    onFirstFrame,
    onEnded,
    onRequestStop,
    seek,
    stop,
  };
};

export default Player;
